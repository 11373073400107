$(document).ready(function() {
	if(!document.querySelector('.C0055')) return false;
	
	var component55 = {
		$el : $('.C0055'),
		init : function() {
			this.$el.find('.carousel-wrap.type-hero').each(function() {
				if($(this).find('.carousel-box').length>1) {
					component55.runSlick($(this));
				} else {
					$(this).addClass('active');
				}
			});
			this.$el.find('.carousel-wrap.type-etc').each(function() {
				$(this).addClass('active');
			});

			/* LGEGMC-324 20200715 add */
			this.$el.find('.carousel-wrap').each(function() {
				var $target = $(this).find('video:visible[autoplay][muted]');
				if ($target.length) {
					setTimeout(function(){
						var targetVideo = $target[0];
						(targetVideo.paused && targetVideo.muted) && targetVideo.play();
					},300);
				}
			});
			/* //LGEGMC-324 20200715 add */
		},
		runSlick: function($obj) {
			var fontColor = 'black';
			if($obj.find('.carousel-box').eq(0).find('.text-area').hasClass('text-white')) {
				fontColor = 'white';
			}
			$obj.on('init', function(slick){
				var $this = $(this);
				$this.find('.slick-slide').attr('role', 'tabpanel');
			}).slick({
				//adaptiveHeight: true,
				infinite: false, //LGEGMO-5525 modify
				listStyle: true, //LGEGMO-5525 modify
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : true,
				dots: true,
				//adaptiveHeight: true,
				// lazyLoad: 'ondemand',
				autoplay: true,
				autoplaySpeed: 8000,
				prevArrow: carouselOptions.bigAnglePrev, // common.js variable
				nextArrow: carouselOptions.bigAngleNext, // common.js variable
				//appendDots:$obj.find('.slick-indicator .slick-dot-wrap')
				appendDots:$obj.prev().find('.slick-dot-wrap')
			});
			$obj.addClass('active');

			$obj.addClass('slick-'+fontColor);
			$obj.on('beforeChange', function(event, slick, currentSlide, nextSlide){
				$obj.find('.carousel-box[data-slick-index='+nextSlide+'] .visual-area img[data-src]').addClass('lazyload');
			});
			$obj.on('afterChange', function(event, slick, currentSlide){
				if($obj.find('.slick-current .text-area').hasClass('text-white')) {
					fontColor = 'white';
				} else {
					fontColor = 'black';
				}
				$obj.removeClass('slick-white').removeClass('slick-black').addClass('slick-'+fontColor);
			});
			$obj.prev().find('.slide-pause').addClass('active').on('click',function(e){
				e.preventDefault();
				if ($(this).hasClass('pause')) {
					$(this).removeClass('pause').addClass('play');
					$(this).text($(this).attr('data-title-play'));
					$(this).attr('aria-label',$(this).attr('data-title-play')); /* PJTWAUS-1 : 20200108 add */
					$obj.slick('slickPause');
				} else {
					$(this).removeClass('play').addClass('pause');
					$(this).text($(this).attr('data-title-stop'));
					$(this).attr('aria-label',$(this).attr('data-title-stop')); /* PJTWAUS-1 : 20200108 add */
					$obj.slick('slickPlay');
				}
			});
			// $obj.find('.slide-pause').trigger('click');
		}
	};
	component55.init();
	
	$('.C0055').each(function(){
		var $iconblock = $(this).find('.C0088');
		if ($iconblock.length) {
			var componentCarouselSimple = {
				$el : null,
				init : function() {
					this.$el = $iconblock;
					this.$el.find('.spec-list').not('.none-slide').not('.slick-initialized').each(function(){
						if($(this).find('.item').length>1) {
							componentCarouselSimple.runSlick($(this));
							$(this).parents('.C0088').addClass('bindSlick');
						}
					});
				},
				runSlick: function($obj) {
					$obj.slick({
						appendDots:$obj.siblings('.slick-indicator').find('.slick-dot-wrap'),
						responsive: [
							{
								breakpoint: 9999,
								settings: 'unslick'
							},
							{
								breakpoint: 767,
								settings: {
									infinite: false,
									slidesToShow: 1,
									slidesToScroll: 1,
									arrows : false,
									dots: true,
								}
							}
						]
					});
					$obj.on('afterChange', function(event, slick, currentSlide){
						//console.log('..');
					});
					$obj.on('breakpoint', function(event, slick, breakpoint) {
						// console.log('breakpoint ' +  breakpoint);
						if( breakpoint === 767 ) {
							eventStopOn();
						}
						if ( breakpoint === 9999 ) {
							$obj.off('mousedown.stop touchstart.stop');
						}
					});
					$(window).on('resize',function(){
						$obj.slick('resize');
						if( ! mql.maxSm.matches) {
							$obj.find('.item').removeAttr('role id tabindex aria-describedby aria-hidden');
						}
					}).resize();
					function eventStopOn() {
						$obj.on('mousedown.stop touchstart.stop', function (e) {
							e.stopPropagation();
						});
					}
					if( mql.maxSm.matches) {
						eventStopOn();
					}
				}
			};
			componentCarouselSimple.init();
		}
	});
	function textAreaPosition() {
		component55.$el.each(function(){
			var $carouselBox = $(this).find('.carousel-box').not('.slick-slide');
			$carouselBox.each(function(){
				var $carouselBox = $(this);
				var $conArea = $carouselBox.find('.contents-area');
				var $visualArea = $carouselBox.find('.visual-area');
				var $textAreaL = $carouselBox.find('.text-area.align-left');
				var $textAreaR = $carouselBox.find('.text-area.align-right');
				var $textArea = $carouselBox.find('.text-area');
				var $mobileTop = $textArea.is('.align-mobile-top');
				if ( $carouselBox.parents('.carousel-wrap').is('.type-hero') ){
					return false;
				}
				if( $textAreaL.length || $textAreaR.length ) {
					if( mql.minMd.matches ) {
						var $textAreaClone = $textArea.clone(true, true);
						$textArea.remove();
						$conArea.prepend($textAreaClone);
						var $con = $conArea.detach();
						$carouselBox.append($con);
						//console.log('pc');
					}
					if ( mql.maxSm.matches ) {
						var $textAreaClone = $textArea.clone(true, true);
						$textArea.remove();
						if ( $mobileTop ){
							$carouselBox.prepend($textAreaClone);
						} else {
							$carouselBox.append($textAreaClone);
						}
						var $con = $conArea.detach();
						$visualArea.append($con);
						//console.log('mobile');
					}
				}
			});
		});
	}
	$(window).on('load resize',textAreaPosition).trigger('resize');
});